@font-face {
font-family: '__anthro_00dbfd';
src: url(/_next/static/media/84dcc80ef3e15a35-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__anthro_00dbfd';
src: url(/_next/static/media/7f2de7604e76de83-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__anthro_Fallback_00dbfd';src: local("Arial");ascent-override: 91.09%;descent-override: 28.61%;line-gap-override: 25.75%;size-adjust: 104.85%
}.__className_00dbfd {font-family: '__anthro_00dbfd', '__anthro_Fallback_00dbfd'
}.__variable_00dbfd {--font-anthro: '__anthro_00dbfd', '__anthro_Fallback_00dbfd'
}

